import { clsx } from 'clsx';
import { forwardRef } from 'react';

import type { ButtonProps } from './type';

import { IconLoading } from '~/components/icons';

import { BUTTON_CLASS_NAME, BUTTON_ICON_SIZE } from './const';

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      theme,
      size,
      block,
      type = 'button',
      disabled,
      loading,
      leftIcon,
      rightIcon,
      children,
      ...props
    },
    ref
  ) => {
    return (
      <button
        className={clsx(
          BUTTON_CLASS_NAME({ theme, size, block }),
          'tw-inline-flex tw-items-center tw-justify-center tw-gap-1',
          'tw-text-nowrap'
        )}
        type={type}
        ref={ref}
        disabled={disabled || loading}
        {...props}
      >
        {loading ? (
          <IconLoading boxSize={BUTTON_ICON_SIZE[size ?? 'md']} />
        ) : (
          leftIcon ?? null
        )}
        {children}
        {rightIcon ?? null}
      </button>
    );
  }
);

Button.displayName = 'Button';
