import { tv } from 'tailwind-variants';

import type { ButtonVariants } from './type';

export const BUTTON_CLASS_NAME = tv({
  base: 'tw-rounded tw-duration-200 disabled:tw-cursor-not-allowed',
  variants: {
    // NOTE: dangerOutlined, linkのdisabledスタイルは、必要になったらデザイナーとスタイル設定について相談する
    theme: {
      primary:
        'tw-border-[0.8px] tw-border-gray-200 tw-bg-primary-500 tw-text-white hover:tw-bg-primary-400 disabled:tw-opacity-50',
      outlined:
        'tw-border-[0.8px] tw-border-gray-200 tw-bg-white tw-text-gray-700 hover:tw-text-primary-400 disabled:tw-text-[#5D575680]',
      danger:
        'tw-border-[0.8px] tw-border-gray-200 tw-bg-red-500 tw-text-white hover:tw-bg-red-400 disabled:tw-opacity-50',
      dangerOutlined:
        'tw-border-[0.8px] tw-border-gray-200 tw-border-gray-200 tw-bg-white tw-text-red-500 hover:tw-text-red-400',
      linkGreen: 'tw-text-nowrap tw-text-primary-500',
      link: 'tw-px-0 tw-text-gray-600 tw-underline',
      dangerLink: 'tw-text-red-500 tw-underline',
    },
    size: {
      xs: 'tw-px-2 tw-py-1 tw-text-ui12 tw-font-semibold',
      sm: 'tw-min-h-7 tw-px-[7.2px] tw-py-[5.2px] tw-text-ui12 tw-font-semibold',
      md: 'tw-min-h-9 tw-px-[11.2px] tw-py-[7.2px] tw-text-ui13 tw-font-semibold',
    },
    block: {
      true: 'tw-w-full',
      false: 'tw-w-auto',
    },
  },
  defaultVariants: {
    theme: 'primary',
    size: 'md',
    block: false,
  },
});

export const BUTTON_ICON_SIZE: Record<
  NonNullable<ButtonVariants['size']>,
  string
> = {
  xs: '13px',
  sm: '18px',
  md: '18px',
};
