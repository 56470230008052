import type { IconBaseProps } from '../type';

export const IconLoading = ({
  boxSize = '1.5em',
  ariaLabel = '読み込み中',
}: IconBaseProps) => {
  return (
    <svg
      width={boxSize}
      height={boxSize}
      viewBox="0 0 24 24"
      fill="none"
      className="tw-animate-spin"
      aria-label={ariaLabel}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 12C19 8.13401 15.866 5 12 5V3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12H5C5 15.866 8.13401 19 12 19C15.866 19 19 15.866 19 12Z"
        fill="currentColor"
      />
    </svg>
  );
};
